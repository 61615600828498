<template>
  <div class="relative">
      <div class="absolute top-50px left-100px">
          <a class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase" href="/">
              <blacklogo class="" />
          </a>
      </div>
      <main class="pt-200px pb-100px">
          <div class="container flex flex-col">
              <div class="questions__wrapper">
  
                  <div v-for="(section) in questions" :key="section.id">
                      <div v-for="(subsection) in section" :key="subsection.id">
                          <p class="text-large my-50px">{{ subsection.name }}</p>
                          <div v-for="(question) in subsection.questions" :key="question.id">
                              <p>{{ question.text }}</p>
                              <div class="flex items-center mb-50px mt-25px">
                                  <div v-for="(answer) in question.answers" :key="answer.id" class="relative questionnaires__input-wrapper bg-greyblue hover:bg-teal focus:bg-teal hover:text-white rounded p-16px mr-10px" :class="(answer.answered ? 'bg-teal text-white' : 'text-black')">
                                      <span class="questionnaires__label"> {{ answer.text }} </span>
                                      <input type="radio" v-bind:aid="answer.id" v-bind:qid="question.id" v-on:click.prevent="testAnswers(question, answer); answer.answered = !answer.answered;" :class="(answer.answered ? 'bg-teal text-white' : 'text-black')" v-bind:checked="answer.checked" class="questionnaires__input" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
  
              </div>
          </div>
      </main>
      <div class="fixed w-full z-10 p-4 flex items-center bg-black color-white questionaires__fixed-footer">
          <router-link class="btn btn--arrow btn--arrow-white bg-teal text-white text-secondary px-6 py-4 ml-25px rounded-full" to="/">
              View Results
          </router-link>
          <router-link class="block text-center text-white w-3/12 underline ml-auto duration-300" to="/">
              Save & complete later
          </router-link>
      </div>
  </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import axios from "axios";
  import firebase from "firebase";
  import store from "../../../store";
  import blacklogo from '@/assets/svg/blacklogo.svg';
  
  export default {
      name: "Leadership",
  
      components: {
          blacklogo,
      },
  
      data() {
          return {
              submission: {
                  answers: []
              },
              questions: [],
              companies: [],
              multipleResults: '',
          };
      },
  
      watch: {
          companies: {
              handler(newValue) {
                  this.getQuestions(newValue);
              },
              deep: true
          }
      },
  
      created() {
  
          firebase.auth().onAuthStateChanged(user => {
              store.dispatch("fetchUser", user);
  
              axios.defaults.headers = {
                  'Content-Type': 'application/json',
                  Authorization: user.za
              }
  
              let endpoints = [
                  'https://pdai-api-7uc2rb5qma-nw.a.run.app/questions/get/4',
                  'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                  'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/4'
              ];
  
              Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                  .then(([{
                      data: questions
                  }, {
                      data: companies
                  }, {
                      data: multipleResults
                  }, ]) => {
                      this.questions = questions;
                      this.companies = companies;
                      this.multipleResults = multipleResults;
                  });
  
          });
  
      },
  
      // check multiple results and return the correct result id then changes this.questions to the correct questions
  
      methods: {
  
          getQuestions(companyInfo) {
              let companyResults = companyInfo.results;
              let resultID = null;
  
              for (let companyResult of companyResults) {
                  let companySections = companyResult.section;
                  for (let companySection of companySections) {
                      if (companySection.name == "Barriers") {
                          resultID = companyResult.id;
                          break;
                      }
                  }
  
                  if (resultID) {
                      break;
                  }
              }
  
              if (resultID) {
                  axios
                      .get(`https://pdai-api-7uc2rb5qma-nw.a.run.app/questions/get/4/${resultID}`)
                      .then(response => {
                          this.questions = response.data;
                      });
              } else {
                  // Handle the case where resultID is not found
              }
          },
  
          testAnswers(question, answer) {
              question.answers.forEach(a => Vue.set(a, 'checked', false));
              answer.checked = true;
              let index = this.submission.answers.findIndex(q => q.questionId === question.id);
              let ans = {
                  qid: question.id,
                  aid: answer.id,
                  answered: true,
              };
              if (index === -1) {
                  this.submission.answers.push(ans);
              } else {
                  // this.submission.answers.splice(index, 1);
                  this.submission.answers.push(ans);
              }
  
              let companyInfo = this.companies;
  
              let companyResults = companyInfo.results;
  
              let resultID = null;
  
              for (let companyResult of companyResults) {
  
                  let companySections = companyResult.section;
  
                  for (let companySection of companySections) {
                      if (companySection.name == "Barriers") {
                          resultID = companyResult.id;
                          break;
                      }
                  }
  
                  if (resultID) {
                      break;
                  }
              }
  
              firebase.auth().onAuthStateChanged(user => {
                  store.dispatch("fetchUser", user);
  
                  axios.defaults.headers = {
                      'Content-Type': 'application/json',
                      Authorization: user.za
                  }
  
                  if (resultID) {
                      axios.post(`https://pdai-api-7uc2rb5qma-nw.a.run.app/result/add_answer/4/${resultID}`, ans);
                  } else {
                      axios.post(`https://pdai-api-7uc2rb5qma-nw.a.run.app/result/add_answer/4`, ans)
                          .then(response => {
                              resultID = response.data.id;
                          });
                  }
              });
          },
  
      },
  
  };
  </script>
  