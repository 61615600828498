<template>
    <div>
        <navbar />
        <main class="bg-greyblue">
            <BarriersPreformance />
            <peer-table class="mb-8"/>
            <trends />
            <Footer />
        </main>
    </div>
    </template>
    
    <script>
    import axios from "axios";
    import firebase from "firebase";
    import store from "../../store";
    import Navbar from "@/views/navs/ReviewNavbar.vue";
    import BarriersPreformance from "@/views/organisms/BarriersPreformance.vue";
    import PeerTable from "@/views/tables/PeerTable.vue";
    import Trends from "@/views/trends/Barriers.vue";
    import Footer from "@/views/footers/Footer.vue";
    
    export default {
    
        components: {
            Navbar,
            BarriersPreformance,
            Trends,
            PeerTable,
            Footer
        },
    
        data() {
            return {
                finInfo: [],
            }
        },
    
        created() {
    
            firebase.auth().onAuthStateChanged(user => {
                store.dispatch("fetchUser", user);
    
                axios.defaults.headers = {
                    'Content-Type': 'application/json',
                    Authorization: user.za
                }
    
                let endpoints = [
                    'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/financial',
                ];
    
                Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                    .then(([{
                        data: finInfo
                    }]) => {
                        this.finInfo = finInfo;
                    });
    
                // console.log(this.finInfo)
    
            });
    
        },
    
        methods: {
            signOut() {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        this.$router.replace("/");
                    })
                    .catch((error) => console.log(error.message));
            },
    
            
        },
    };
    </script>
    <style scoped>
    .nav-button {
        color: black!important;
    }
    </style>